@import '../../styles/lib/helpers';

// =====================================
// =====================================
// =====================================
// HOW TO USE THIS FILE
// =====================================
// =====================================
// =====================================

// Subbuzz markup and in some cases styling comes from Subbuzz Rendering
// API, a generic rendering service. If you want an article element to
// display in a specific way for web article pages only, you should
// create an override for that element's style here.
//
// If there is an existing override file for the element you are interested
// in, feel free to add the style to that file. Otherwise, please create
// a new partial in the `style-overrides` directory and import it below

@import './style-overrides/image';
@import './style-overrides/embeds';
@import './style-overrides/text';
@import './style-overrides/quiz';
@import './style-overrides/featured-post';
@import './style-overrides/bfp-instant-quiz';
@import './style-overrides/bfp-related-links';
@import './style-overrides/breaking-post-legacy';
@import './style-overrides/trending-product';
@import './style-overrides/subbuzzes-news';

.subbuzz {

  h2 {
    @include jp-font-size('text-5');
    @include breakpoint("sm") {
      @include jp-font-size('text-4');
    }
  }

  h3 {
    @include breakpoint("sm") {
      @include jp-font-size('text-5');
    }
  }

  .Ad .ad__disclosure--ex,
  .Ad .ad__disclosure--bfp {
    font-size: 0.75rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.3;
    font-weight: 300;
  }
}
