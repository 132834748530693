// Styles to support display of content in international breaking news posts
// Breaking templates are not supported in the CMS anymore but we want to be sure old posts can still be read
// -------------------------------------------------------------------------

.featured-post .buzz__update {
  .subbuzz.subbuzz-text p {
    // non-japanese styling is handled in SRA
    @include jp-font-size('text-5');
  }
}

// This class is added within bf_bpage_ui, not subbuzz-rendering
.buzz__update-title {
  border-bottom: 1px solid $gray-3;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
}

.subbuzz.buzz-type--breaking__subbuzz .subbuzz__description p {
  font-size: 0.875rem;
}
