@import "bf-solid/_lib/solid-helpers/variables";
@import "bf-solid/_lib/solid-helpers/mixins";
@import "~@buzzfeed/adlib/core/sass/variables";

@import "ads/solid-polyfill";
@import "~@buzzfeed/adlib/core/sass/base";
@import "~@buzzfeed/adlib/core/sass/_mwb_survey";
@import "~@buzzfeed/adlib/components/awareness/awareness";
@import "~@buzzfeed/adlib/components/bigstory/bigstory";
@import "~@buzzfeed/adlib/components/buzz-format/buzz";
@import "~@buzzfeed/adlib/components/ex/ex";
@import "~@buzzfeed/adlib/components/bpage-story/bpage-story";
@import "~@buzzfeed/adlib/components/grid-story/grid-story";
@import "~@buzzfeed/adlib/components/promo-inline/promo-inline";
@import "~@buzzfeed/adlib/components/toolbar/toolbar";

.ad-scroll-sub--enabled .Ad {
  display: none !important;
}

.Ad {
  @import "ads/awareness";
  @import "ads/bpage-story";
  @import "ads/inline";
  @import "ads/link-advertise";
  @import "ads/bottom-grid";

  // FIXME: Patch for the Bright Playlist video becoming 0x0
  .ad-bigstory.ad-flexible--bfp_native_instream_video .ad-slot {
    width: 100% !important;
  }

  // very specific Bright Playlist Inline styles
  .ad-promo-inline.ad-flexible--bfp_native_instream_video {
    border-bottom: 1rem solid $fill-gray-lighter;

    .ad-text-disclosure {
      background-color: $fill-gray-lighter;
    }

    @include breakpoint("sm") {
      border-bottom: 1px solid $fill-gray-lighter;

      .ad-text-disclosure {
        background-color: transparent;
      }
    }
  }
}

.quiz-sticky-ad .ad-promo-inline.ad-flexible {
  overflow-x: visible;
  padding-bottom: 0;

  @media (max-width: 39.999rem) {
    padding-bottom: 0.5rem;
  }

  .ad__disclosure--ex {
    padding-top: 0.5rem;
  }
}
.quiz-sticky-ad .ad-wireframe-wrapper--inline {
  background-color: white;
}

.Ad--partner {
  transition: opacity 200ms, max-height 200ms;
  min-height: auto;
  max-height: 1000px; // should be enough
  opacity: 1;

  // ad slots on advertiser posts should be collapsed by default
  // (note that they should still be displayed and be bigger than 0x0,
  // otherwise GPT may decide not to fetch ads)
  &:not(.Ad--loaded) {
    min-height: 0;
    max-height: 1px;
    overflow: hidden;
    margin-top: -1px;
    margin-bottom: 0;
    opacity: 0;
  }
}

@include solid-breakpoint-before("sm") {
  .ad-inline .Ad {
    margin-left: -16px;
    margin-right: -16px;
  }
}
