@import "lib/helpers";

[data-feed-main-container-id="taboola-below-article-feed"] .trc_related_container .video-title {
  color: #222222 !important;
  font-size: 0.875rem !important;
  line-height: 1.2 !important;
  font-family: "Basier", Helvetica, Arial, sans-serif !important;

  @include breakpoint("md") {
    font-size: 1.125rem !important;
  }
}
