@import "../lib/helpers";

.ad-wireframe-image {
  background: $gray-1;
}

.ad-grid-thumbnail,
.ad__wireframe-container {
  border-radius: 4px;
  overflow: hidden;

  & + .ad-buzz-title {

    h2, span {
      font-size: .875rem;
      line-height: 1.21;
      display: block;
      font-weight: $bold;

      @include breakpoint("md") {
        font-size: 1.125rem;
        line-height: 1.2;

        html[lang="ja"] main & {
          font-size: 1rem;
          line-height: 1.5;
        }
      }
    }
  }
}

