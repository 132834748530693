@use "sass:math";
@import './reorder-credits';

// credits within legacy quizzes
// TODO: remove once all quizzes are rendered by BFP Quiz
.media__credit {
  color: $black;
  margin-top: 0.5rem;

  a {
    color: $black;

    &:hover, &:focus {
      color: $blue;
    }
  }
}

// Special case of long "news" posts (e.g. UK news) rendered on BF, which
// has a different credit style
.subbuzzes--buzzfeed.buzz--long {
  .subbuzz-image {
    @include reorder-credits();
  }
}
