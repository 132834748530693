@import "../../../styles/lib/variables";
.subbuzz-bfp .bfp-related-links {
  .bfp-related-links__list {
    padding-left: 0;
  }
  .bfp-related-links__list-item {
    list-style: none;
  }
  .bfp-related-links__link {
    font-weight: $bold;
    font-size: $text-5;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    @include breakpoint("sm") {
      font-size: $text-4;
      line-height: 1.275;
    }
  }
}

// BF specific overrides
.subbuzzes-wrapper.subbuzzes--buzzfeed {
  .bfp-related-links__list-item {
    margin-bottom: 2rem;
  }
  .bfp-related-links__title {
    font-weight: $bold;
    font-size: $text-3;
    letter-spacing: -0.8px;
    line-height: 1.2;
    margin-bottom: $space-3;
    text-transform: capitalize;
    &:before {
      content: "";
      background: $gradient-pink-purple;
      border-radius: 4px;
      display: block;
      height: 8px;
      margin-bottom: 0.5rem;
      width: 72px;
    }
    @include breakpoint("sm") {
      font-size: $text-2;
      line-height: 1.2815;
    }
  }
  .bfp-related-links__link {
    &:hover,
    &:focus {
      color: $blue;
      text-decoration: none;
    }
  }
  .bfp-related-links__author {
    font-weight: 700;
    line-height: 1.375;
  }
  .bfp-related-links__meta {
    color: $black;

    [aria-hidden="true"] {
      font-weight: 700;
      font-size: 1.2em;
      display: inline-block;
      padding: 0 5px;
    }
  }
}

// BFN specific overrides
.subbuzzes-wrapper.subbuzzes--buzzfeed_news {
  .subbuzz-bfp--related_links {
    margin-bottom: 16px;
  }
  .bfp-related-links {
    padding: 16px 0 12px 0;
    border-top: 1px solid #c8c8c6;
    padding-bottom: 2px;

    .bfp-related-links__link {
      text-decoration: none;
      &:hover {
        color: $news-c-black;
        text-decoration: underline;
      }
    }
    .bfp-related-links__list-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .bfp-related-links__list-item div:nth-child(2) {
      width: 69%;
    }

    .bfp-related-links__author {
      color: #000;
    }

    span.bfp-related-links__author::after {
      content: "•";
      color: #A0A1A0;
      margin: 0 5px 0 10px;
      font-weight: 700;
      font-size: 1rem;
    }

    .bfp-related-links__image {
      display: flex !important;
      margin-right: 10px;
      width: calc(31% - 10px);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bfp-related-links__meta,
    .bfp-related-links__meta [aria-hidden='true'] {
      display: none;
    }

    .bfp-related-links__link,
    .bfp-related-links__title {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 0;
    }

    .bfp-related-links__link {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .bfp-related-links__title {
      color: $news-c-red;
      text-transform: uppercase;
      letter-spacing: .03125rem;
    }

    @include breakpoint("sm") {
      .bfp-related-links__link,
      .bfp-related-links__title {
        font-size: 20px;
        line-height: 28px;
      }
      .bfp-related-links__list-item {
        padding-top: 8px;
      }
    }

    .bfp-related-links__link-title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    @media (min-width: 380px) {
      .bfp-related-links__link-title {
        -webkit-line-clamp: 4;
      }
    }
  }

  @include breakpoint("sm") {
    .bfp-related-links {
      padding: 24px 0 10px 0;
    }
  }
}
