@import "../../../styles/lib/helpers";
@import "../../../styles/lib/variables";

.subbuzz {
  // Trending product labels are dynamically added to subbuzzes
  // via the TrendingProducts component
  .trending-product-label-wrapper {
    margin-top: 1rem;

    .trending-product-label {
      margin-bottom: 1rem;
      font-size: 14px;
      font-weight: 700;
      display: inline-block;
      text-transform: uppercase;
      position: absolute;
      left: -8px;
      z-index: 9;
      background: #FFE3D4;
      border: 1px solid $black;
      box-shadow: 3px 3px 0px 0px $black;
      transform: rotate(-16deg);
      padding: 8px 12px;

      @include breakpoint("md") {
        font-size: 16px;
        transform: rotate(-8deg);
      }
    }
  }
}
