// TODO: Move this somewhere else
// Currently we are not applying any of the custom styles to quantcast popup that get applied to other pagers from buzzblocks
// Most of them are not used in cmp2 anyway but we need this to center buttons,
// adding it here for now instead of pulling whole buzzblocks package
// https://github.com/buzzfeed/mono/blob/6ee9ba993a137abebba8b52fc6ba1f765114ceaa/packages/frontend/buzzblocks/components/consent-ui/consent-ui.module.scss#L88-L91
:global {
  .qc-cmp2-footer.qc-cmp2-footer-overlay.qc-cmp2-footer-scrolled {
    margin: auto;
  }
}
