@font-face {
  font-family: "Pensum";
  src: url("../fonts/PensumPro-Book.woff2") format("woff2"), url("../fonts/PensumPro-Book.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Pensum";
  src: url("../fonts/PensumPro-Bold.woff2") format("woff2"), url("../fonts/PensumPro-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Pensum";
  src: url("../fonts/PensumPro-BookItalic.woff2") format("woff2"), url("../fonts/PensumPro-BookItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Pensum";
  src: url("../fonts/PensumPro-BoldItalic.woff2") format("woff2"), url("../fonts/PensumPro-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Basier";
  src: url("../fonts/BasierSquare-Regular.woff2") format("woff2"), url("../fonts/BasierSquare-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Basier";
  src: url("../fonts/BasierSquare-Bold.woff2") format("woff2"), url("../fonts/BasierSquare-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Basier";
  src: url("../fonts/BasierSquare-RegularItalic.woff2") format("woff2"), url("../fonts/BasierSquare-RegularItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Basier";
  src: url("../fonts/BasierSquare-BoldItalic.woff2") format("woff2"), url("../fonts/BasierSquare-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Basier Square Narrow";
  src: url("../fonts/BasierSquareNarrow-Regular.woff2") format("woff2"), url("../fonts/BasierSquareNarrow-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Basier Square Narrow";
  src: url("../fonts/BasierSquareNarrow-Bold.woff2") format("woff2"), url("../fonts/BasierSquareNarrow-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Basier Square Narrow";
  src: url("../fonts/BasierSquareNarrow-RegularItalic.woff2") format("woff2"), url("../fonts/BasierSquareNarrow-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Basier Square Narrow";
  src: url("../fonts/BasierSquareNarrow-BoldItalic.woff2") format("woff2"), url("../fonts/BasierSquareNarrow-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Mayonnaise";
  src: url("../fonts/Mayonnaise-Black_web.woff2") format("woff2"), url("../fonts/Mayonnaise-Black_web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Mayonnaise";
  src: url("../fonts/Mayonnaise-Extra-Black_web.woff2") format("woff2"), url("../fonts/Mayonnaise-Extra-Black_web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Transmitter Compressed";
  src: url("../fonts/TransmitterCmpr-Medium.woff2") format("woff2"), url("../fonts/TransmitterCmpr-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Transmitter Compressed";
  src: url("../fonts/TransmitterCmpr-MediumItalic.woff2") format("woff2"), url("../fonts/TransmitterCmpr-MediumItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Lifted Serif";
  src: url("../fonts/Lifted-Serif.woff2") format("woff2"), url("../fonts/Lifted-Serif.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
