@use "sass:math";
// =====================================
// =====================================
// =====================================
// DO NOT ADD EXTRA CLASSES TO THIS FILE
// =====================================
// =====================================
// =====================================

// This file contains the bare minimum viable Solid (a BuzzFeed style library -
// https://solid.buzzfeed.com/) classes for ads that are not already included in Subbuzzes/style-overrides/solid-polyfill.module.scss.
// If other classes aren't supported on an individual ad unit, update the ad code itself
// to not rely on a Solid class.

// While we are not using Solid in the bf_bpage_ui application,
// these classes are in the markup being returned from adlib.
// They will be removed when adlib is updated to not use solid.
@import "bf-solid/_lib/solid-helpers/variables";
@import "bf-solid/_lib/solid-helpers/mixins";
@import "~@buzzfeed/adlib/core/sass/variables";

.xs-fixed {
  position: fixed;
}

.xs-z4 {
  z-index: $z4;
}

.xs-t1 {
  top: $space-1;
}

.xs-r1 {
  right: $space-1;
}

.xs-flex-order-1 {
  order: 1;
}

.xs-flex-order-2 {
  order: 2;
}

.xs-flex-row {
  flex-direction: row;
}

.xs-flex-align-start {
  align-items: flex-start;
}

.xs-float-none {
  float: none;
}

.xs-fit {
  max-width: 100%;
}

.clearfix:before,
%clearfix:before,
.clearfix:after,
%clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.xs-mr0 {
  margin-right: 0;
}

.xs-mx0 {
  margin-left: 0;
  margin-right: 0;
}

.xs-my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.xs-mt05 {
  margin-top: $space-05;
}

.xs-mr05 {
  margin-right: $space-05;
}

.xs-my3 {
  margin-top: $space-3;
  margin-bottom: $space-3;
}

.xs-my4 {
  margin-top: $space-4;
  margin-bottom: $space-4;
}

.xs-pl0 {
  padding-left: 0;
}

.xs-px0 {
  padding-left: 0;
  padding-right: 0;
}

.xs-py0 {
  padding-top: 0;
  padding-bottom: 0;
}

.xs-pt05 {
  padding-top: $space-05;
}

.xs-pl05 {
  padding-left: $space-05;
}

.xs-px05 {
  padding-left: $space-05;
  padding-right: $space-05;
}

.xs-p1 {
  padding: $space-1;
}

.xs-pt1 {
  padding-top: $space-1;
}

.xs-px1 {
  padding-left: $space-1;
  padding-right: $space-1;
}

.xs-pt2 {
  padding-top: $space-2;
}

.xs-pb2 {
  padding-bottom: $space-2;
}

.align-top {
  vertical-align: top;
}

.align-bottom {
  vertical-align: bottom;
}

.xs-col-1 {
  width: percentage(math.div(1,12));
}

.xs-col-5 {
  width: percentage(math.div(5,12));
}

.xs-col-7 {
  width: percentage(math.div(7,12));
}

.xs-text-right {
  text-align: right;
}

.regular,
.normal {
  font-weight: $regular;
}

.bold {
  font-weight: $bold;
}

.xbold {
  font-weight: $xbold;
}

.xs-text-3 {
  font-size: $text-3;
  line-height: $line-height-1;
}

.xs-text-4 {
  font-size: $text-4;
  line-height: $line-height-2;
}

.xs-text-5 {
  font-size: $text-5;
  line-height: $line-height-2;
}

.xs-text-6 {
  font-size: $text-6;
  line-height: $line-height-2;
}

.text-gray {
  color: $text-gray;
}

.text-white {
  color: $text-white;
}

.text-gray-lighter {
  color: $text-gray-lighter;
}

.text-gray-lightest {
  color: $text-gray-lightest;
}

.text-promoted-orange {
  color: $text-promoted-orange;
}

.link-gray {
  color: $text-gray;

  &:hover {
    color: $text-blue;
  }
}

.link-gray-lighter {
  color: $text-gray-lightest;

  &:hover {
    color: $text-gray;
  }
}

.fill-blue {
  background-color: $fill-blue;
}

.fill-yellow {
  background-color: $fill-yellow;
}

.fill-yellow-lighter {
  background-color: $fill-yellow-lighter;
}

.fill-gray {
  background-color: $fill-gray;
}

.fill-gray-darker {
  background-color: $fill-gray-darker;
}

@include solid-breakpoint("sm") {
  .sm-absolute {
    position: absolute;
  }

  .sm-r0 {
    right: 0;
  }

  .sm-b0 {
    bottom: 0;
  }

  .sm-overflow-auto {
    overflow: auto;
  }

  .sm-inline {
    display: inline;
  }

  .sm-float-left {
    float: left;
  }

  .sm-float-right {
    float: right;
  }

  .sm-full-height {
    height: 100%;
  }

  .sm-pt0 {
    padding-top: 0;
  }

  .sm-pb0 {
    padding-bottom: 0;
  }

  .sm-pl0 {
    padding-left: 0;
  }

  .sm-pb05 {
    padding-bottom: $space-05;
  }

  .sm-pl05 {
    padding-left: $space-05;
  }

  .sm-pt1 {
    padding-top: $space-1;
  }

  .sm-p2 {
    padding: $space-2;
  }

  .sm-px2 {
    padding-left: $space-2;
    padding-right: $space-2;
  }

  .sm-py2 {
    padding-top: $space-2;
    padding-bottom: $space-2;
  }

  .sm-col-5 {
    width: percentage(math.div(5,12));
  }

  .sm-col-7 {
    width: percentage(math.div(7,12));
  }

  .sm-text-2 {
    font-size: $text-2;
    line-height: $line-height-1;
  }

  .sm-text-4 {
    font-size: $text-4;
    line-height: $line-height-2;
  }
}


@include solid-breakpoint("md") {
  .md-float-none {
    float: none;
  }

  .md-mt0 {
    margin-top: 0;
  }

  .md-mt05 {
    margin-top: $space-05;
  }

  .md-mb2 {
    margin-bottom: $space-2;
  }

  .md-pt1 {
    padding-top: $space-1;
  }

  .md-px2 {
    padding-left: $space-2;
    padding-right: $space-2;
  }

  .md-col-1 {
    width: percentage(math.div(1,12));
  }

  .md-col-4 {
    width: percentage(math.div(4,12));
  }

  .md-col-12 {
    width: percentage(math.div(1,12));
  }

  .md-text-left {
    text-align: left;
  }

  .md-flex {
    display: flex;
  }
}

@include solid-breakpoint("lg") {
  .lg-mx2 {
    margin-left: $space-2;
    margin-right: $space-2;
  }

  .lg-pl0 {
    padding-left: 0;
  }
}
