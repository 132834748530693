@import '../../../styles/lib/helpers';
@import './reorder-credits';

.subbuzzes--buzzfeed_news {
  &:after {
    clear: both;
    display: block;
    content: '';
  }
  .js-news-product {
    background-color: #f1f0ec;
    padding: 1px 3px 4px;
  }
  // Reorder credits (caption/description) for all non-list subbuzzes
  .subbuzz:not(.buzz-type--list__subbuzz) {
    @include reorder-credits();
  }
  // BFN has centered tweets; Just for list posts, uncenter tweet content.
  .subbuzz.buzz-type--list__subbuzz {
    .tweet__container {
      margin: 8px 0;
    }
    .subbuzz__caption {
      margin: 0;
      max-width: initial;
    }
  }

  .subbuzz {
    &.subbuzz-text li {
      margin-bottom: 1rem;
      line-height: 1.5;
    }

    &.subbuzz-image--outset-both  {
      @include breakpoint("md") {
        .subbuzz__media--full-width-container {
          margin-left: 0;
          margin-right: 0;
        }
      }
      @include breakpoint("lg") {
        .subbuzz__media--full-width-container {
          margin-left: -26%;
          margin-right: -26%;
        }
      }
    }
  }

  .subbuzz-bfp {
    .google-maps-custom-info-window {
      font-family: $basier;
    }
  }

  .split__photo {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  #publicGood {
    &.loading {
      padding: 60px 0px;
      border-top: 1px solid $gray-5;
    }
  }
}
