// Instagram
// -------------------------

.instagram__container {
  border-radius: 4px !important;
  border: 2px solid $gray-1 !important;
  border-bottom: 0 !important;
  // we need to use box shadow on the bottom to preserve iframe padding
  box-shadow: 0 2px 0 0 $gray-1 !important;
  @include breakpoint("sm") {
    border-radius: 8px !important;
    border: 4px solid $gray-1 !important;
    border-bottom: 0 !important;
    box-shadow: 0 4px 0 0 $gray-1 !important;
  }
}
