@import "../../../styles/lib/helpers";
/* stylelint-disable declaration-no-important */
// This overrides connatix styles onsite instead of w/in player style
// to have all of the styling in one place.
$cnx-border-radius: 4px;

.bf-cnx-override {
  border-radius: $cnx-border-radius;
  overflow: hidden;

  .cnx-main-container {
    border: 0px hidden;
    border-radius: $cnx-border-radius;
  }

  .cnx-video-wrapper {
    border-top-left-radius: $cnx-border-radius;
    border-top-right-radius: $cnx-border-radius;
    overflow: hidden;
  }

  &.variant-header-only {
    .cnx-main-container {
      margin-top: 2rem;
    }
  }

  &.variant-thumbnail-only {
    .cnx-g-container {
      color: $white;
      background: $black;
      padding: $space-1 $space-1 0;
      height: 150px !important;
    }
    .cnx-video-container .cnx-icon-button svg {
      width: 15px !important;
      height: 15px !important;
    }
    .cnx-video-container .cnx-icon-button:before {
      background-color: rgba(255, 255, 255, 0.65);
    }
    .cnx-volume-container.cnx-mod-hover {
      background-color: rgba(255, 255, 255, 0.45);
    }
    .cnx-rail,
    .cnx-g-explore-more,
    .cnx-g-card-slot,
    .cnx-g-card-text {
      color: $white !important;
    }
    .cnx-g-card-text {
      font-size: $text-6;
    }
    .cnx-g-explore-more {
      font-size: $text-6 !important;
      padding-left: $space-1 !important;
    }
    .cnx-rail,
    .cnx-g-rail {
      margin-left: -$space-1;
    }
    .cnx-scrollbar-wrapper {
      padding-left: $space-1;
    }
    .cnx-g-card-slot {
      width: 325px !important;
    }
    .cnx-g-card:hover,
    .cnx-g-card-slot:hover {
      background: transparent !important;
    }

    .cnx-g-fade-out,
    .cnx-g-triangle, // this is the "current" play indicator
    .cnx-g-card-text-info {
      display: none;
    }

    // play button overrides
    .cnx-g-card-icon {
      opacity: 1 !important;
    }
    .cnx-g-card-icon,
    .cnx-g-card-image,
    .cnx-g-card-image:after {
      background-image: none !important;
    }
    .cnx-g-card-icon,
    .cnx-g-card-image > img {
      border-radius: 0 !important;
    }
    .cnx-g-card-icon {
      background: rgba(0, 0, 0, .3);
    }
    .cnx-g-card-image:hover .cnx-g-card-icon {
      background: rgba(0, 0, 0, .01);
    }
    .cnx-g-rail .cnx-ui-btn {
      background-color: $gray-1;
      border-radius: 24px;
      width: 24px !important;
      height: 24px !important;
    }
    .cnx-g-rail .cnx-icon-button svg {
      width: 13px !important;
      height: 13px !important;
    }
    .cnx-g-rail .cnx-color-icon > path {
      fill: $black !important;
    }
    .cnx-g-rail .cnx-icon-button {
      transform: scaleX(.8);
    }
    // hide play button when actively playing
    .cnx-mod-active .cnx-g-card-icon {
      visibility: hidden;
    }
  }
  .bf-cnx-player {
    margin-bottom: -36px;
    @include breakpoint('lg') {
      margin-bottom: 0;
    }
  }
}
/* stylelint-enable declaration-no-important */
