@use "sass:math";
// =====================================
// =====================================
// =====================================
// DO NOT ADD EXTRA CLASSES TO THIS FILE
// =====================================
// =====================================
// =====================================

// This file contains the bare minimum viable Solid (a BuzzFeed style library -
// https://solid.buzzfeed.com/) classes for subbuzzes and ads. If other classes aren't
// supported on an individual subbuzz, ad, or other component, update the component code itself
// to not rely on a Solid class.

// While we are not using Solid in the bf_bpage_ui application,
// these classes are in the markup being returned by Subbuzz Rendering API and other upstream dependencies.
// We will work to refactor these out over time.
@import "lib/helpers";


//  12 Columm Grid
// -------------------------

.col {
  float: left;
}
.xs-col-2 {
  width: percentage(math.div(2,12));
}

.xs-col-3 {
  width: percentage(math.div(3,12));
}

.xs-col-6 {
  width: percentage(math.div(6,12));
}

.xs-col-8 {
  width: percentage(math.div(8,12));
}

.xs-col-9 {
  width: percentage(math.div(9,12));
}

.xs-col-10 {
  width: percentage(math.div(10,12));
}

.xs-col-11 {
  width: percentage(math.div(11,12));
}

.xs-col-12 {
  width: 100%;
}

.sm-col-3 {
  @include breakpoint("sm") {
    width: percentage(math.div(3,12));
  }
}

.sm-col-4 {
  @include breakpoint("sm") {
    width: percentage(math.div(4,12));
  }
}

.sm-col-8 {
  @include breakpoint("sm") {
    width: percentage(math.div(8,12));
  }
}

.sm-col-9 {
  @include breakpoint("sm") {
    width: percentage(math.div(9,12));
  }
}

.md-col-5 {
  @include breakpoint("md") {
    width: percentage(math.div(5,12));
  }
}

.md-col-8 {
  @include breakpoint("md") {
    width: percentage(math.div(8,12));
  }
}

.lg-col-4 {
  @include breakpoint("lg") {
    width: percentage(math.div(4,12));
  }
}

.lg-col-8 {
  @include breakpoint("lg") {
    width: percentage(math.div(8,12));
  }
}

//  Block Grid
// -------------------------

.block-grid__item {
  display: inline-block;
  margin: 0.5rem;
  font-size: 16px;
  vertical-align: top;
}

.xs-block-grid-1 {
  font-size: 0;
  margin: -0.5rem;
  padding: 0;

  .block-grid__item {
    width: 100%;
  }
}

.sm-block-grid-3 {
  @include breakpoint("sm") {
    font-size: 0 !important;
    margin: -0.5rem !important;
    padding: 0 !important;
  }

  .block-grid__item {
    width: calc(((1 / 3) * 100%) - 1rem);
  }
}

//  Flexbox
// -------------------------

.flex {
  display: flex;
}

.xs-flex-align-center {
  align-items: center;
}

.xs-flex-column {
  flex-direction: column;
}

.xs-flex-grow-1 {
  flex-grow: 1;
}

.xs-flex-justify-center {
  justify-content: center;
}

.xs-flex-justify-space-between {
  justify-content: space-between;
}

.xs-flex-shrink-0 {
  flex-shrink: 0;
}

.sm-flex-justify-space-between {
  @include breakpoint("sm") {
    justify-content: space-between;
  }
}

//  Margin
// -------------------------

.xs-m0 {
  margin: 0;
}

.xs-mb0 {
  margin-bottom: 0;
}

.xs-mb05 {
  margin-bottom: $space-05;
}

.xs-mb1 {
  margin-bottom: $space-1;
}

.xs-mb2 {
  margin-bottom: $space-2;
}

.xs-mb3 {
  margin-bottom: $space-3;
}

.xs-mb4 {
  margin-bottom: $space-4;
}

.xs-ml0 {
  margin-left: 0;
}

.xs-ml05 {
  margin-left: $space-05;
}

.xs-ml1 {
  margin-left: $space-1;
}

.xs-mr1 {
  margin-right: $space-1;
}

.xs-mr2 {
  margin-right: $space-2;
}

.xs-mt0 {
  margin-top: 0;
}

.xs-mt1 {
  margin-top: $space-1;
}

.xs-mt2 {
  margin-top: $space-2;
}

.xs-mt3 {
  margin-top: $space-3;
}

.xs-mx05 {
  margin-left: $space-05;
  margin-right: $space-05;
}

.xs-mx2 {
  margin-left: $space-2;
  margin-right: $space-2;
}

.xs-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.sm-mb0 {
  @include breakpoint("sm") {
    margin-bottom: 0;
  }
}

.sm-mb2 {
  @include breakpoint("sm") {
    margin-bottom: $space-2;
  }
}

.sm-mb3 {
  @include breakpoint("sm") {
    margin-bottom: $space-3;
  }
}

.sm-mr3 {
  @include breakpoint("sm") {
    margin-right: $space-3;
  }
}

.sm-mt0 {
  @include breakpoint("sm") {
    margin-top: 0;
  }
}

.sm-mt05 {
  @include breakpoint("sm") {
    margin-top: $space-05;
  }
}

.md-m4 {
  @include breakpoint("md") {
    margin: $space-4;
  }
}

.md-mb4 {
  @include breakpoint("md") {
    margin-bottom: $space-4;
  }
}

//  Padding
// -------------------------

.xs-p0 {
  padding: 0;
}

.xs-p2 {
  padding: $space-2;
}

.xs-pb1 {
  padding-bottom: $space-1;
}

.xs-pl2 {
  padding-left: $space-2;
}

.xs-pr0 {
  padding-right: 0;
}

.xs-pr1 {
  padding-right: $space-1;
}

.xs-pt0 {
  padding-top: 0;
}

.xs-px2 {
  padding-left: $space-2;
  padding-right: $space-2;
}

.xs-px4 {
  padding-left: $space-4;
  padding-right: $space-4;
}

.xs-py1 {
  padding-top: $space-1;
  padding-bottom: $space-1;
}

.xs-py3 {
  padding-top: $space-3;
  padding-bottom: $space-3;
}

.sm-pl1 {
  @include breakpoint("sm") {
    padding-left: $space-1;
  }
}

.sm-pl2 {
  @include breakpoint("sm") {
    padding-left: $space-2;
  }
}

.sm-pr0 {
  @include breakpoint("sm") {
    padding-right: 0;
  }
}

.sm-pr3 {
  @include breakpoint("sm") {
    padding-right: $space-3;
  }
}

.md-p4 {
  @include breakpoint("md") {
    padding: $space-4;
  }
}

.md-pl4 {
  @include breakpoint("md") {
    padding-left: $space-4;
  }
}

//  Absolute Positioning
// -------------------------

.xs-b0 {
  bottom: 0;
}

.xs-r0 {
  right: 0;
}

.xs-t0 {
  top: 0;
}

//  Positioning
// -------------------------

.xs-absolute {
  position: absolute;
}

.xs-relative {
  position: relative;
}

.xs-z1 {
  z-index: $z1;
}

.xs-z2 {
  z-index: $z2;
}

.xs-z3 {
  z-index: $z3;
}

.xs-float-left {
  float: left;
}

.xs-float-right {
  float: right;
}

.md-float-right {
  @include breakpoint("md") {
    float: right;
  }
}

//  Display
// -------------------------

.xs-block {
  display: block;
}

.xs-hide {
  display: none;
}

.xs-inline-block {
  display: inline-block;
}

.xs-flex {
  display: flex;
}

.sm-flex {
  @include breakpoint("sm") {
    display: flex;
  }
}

.sm-block {
  @include breakpoint("sm") {
    display: block;
  }
}

.sm-hide {
  @include breakpoint("sm") {
    display: none;
  }
}

.sm-inline-block {
  @include breakpoint("sm") {
    display: inline-block;
  }
}

.md-block {
  @include breakpoint("md") {
    display: block;
  }
}

.md-hide {
  @include breakpoint("md") {
    display: none;
  }
}

//  Vertical Align
// -------------------------

.sm-align-middle {
  @include breakpoint("sm") {
    vertical-align: middle;
  }
}

//  Typography
// -------------------------

.italic {
  font-style: italic;
}

.caps {
  text-transform: uppercase;
}

.xs-text-2 {
  @include font-size('text-3');
}

.xs-text-3 {
  @include font-size('text-4');
}

.xs-text-4 {
  @include font-size('text-5');
}

.xs-text-5 {
  @include font-size('text-6');
}

.xs-text-6 {
  font-size: $text-7;
  line-height: 1.25;
}

.xs-text-center {
  text-align: center;
}

.sm-text-left {
  @include breakpoint("sm") {
    text-align: left;
  }
}

.md-text-1 {
  @include breakpoint("md") {
    @include font-size('text-2');
  }
}

.md-text-3 {
  @include breakpoint("md") {
    @include font-size('text-3');
  }
}

.md-text-center {
  @include breakpoint("md") {
    text-align: center;
  }
}

.nowrap {
  white-space: nowrap;
}

//  Height
// -------------------------
.xs-full-height {
  height: 100%;
}

//  Overflow
// -------------------------

.xs-overflow-hidden {
  overflow: hidden;
}

//  Colors
// -------------------------

.link-gray {
  color: $black;

  path {
    fill: $black;
  }

  &:hover {
    color: $blue;

    path {
      transition: fill .15s ease 0s;
      fill: $blue;
      cursor: pointer;
    }
  }
}

.svg-white {
  fill: $white;
}

.text-gray {
  color: $black;
}

.text-gray-lighter, .text-gray-lightest {
  color: $gray-7;
}

.text-white {
  color: $white;
}

.fill-gray-lighter {
  background-color: $gray-1;
}

.fill-white {
  background-color: $white;
}

//  SVG
// -------------------------

.svg-2 {
  vertical-align: middle;
  width: $text-3;
  height: $text-3;
}

.svg-4 {
  vertical-align: middle;
  width: $text-5;
  height: $text-5;
}

//  Borders
// -------------------------

.xs-border {
  border: $border
}

.xs-border-none {
  border: none;
}

//  Buttons
// -------------------------

.button {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  appearance: none;
  user-select: none;
  font-family: inherit;
  padding: .5rem .875rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: $border-radius;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  background-color: #0f65ef;
  color: #fff;
  border-color: #0f65ef;
  transition: background-color .1s ease 0s;

  &:hover,
  &:focus {
    background-color: darken(#0f65ef, 20%);
  }

  &:active {
    background-color: darken(#0f65ef, 35%);
  }
}

.button--icon > svg {
  width: 1rem;
  height: 1rem;
  fill: #fff;
  position: relative;
  top: .125rem;
  margin-right: .5rem;
}

.button--icon.button--small > svg {
  width: .875rem;
  height: .875rem;
  position: relative;
  margin-right: .3125rem;
}

.button--small {
  @extend .button;
  padding: .3125rem .625rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.button--transparent {
  @extend .button;
  background-color: transparent;
  color: #0f65ef;
  border-color: transparent;
  border: 1px solid transparent;
}

.button--transparent:not(.button--disabled):not(:disabled):hover {
  background-color: transparent;
  color: #093c8f;
}

$platforms: (
        copy: $blue,
        facebook: $facebook,
        pinterest: $pinterest,
        twitter: $twitter,
);
@each $name, $platform in $platforms {
  .button--#{$name}.js-sharing-btn {
    background-color: $platform;
    border: 1px solid $platform;
    &:hover {
      background-color: darken($platform, 10%);
      border: 1px solid darken($platform, 10%);
    }
  }
}
// for lineapp and whatspp specifically need to make important/add specificity from poll results styling
.button--whatsapp.js-sharing-btn {
  $whatsapp: #075E54; // using brand branch color for a11y
  background-color: $whatsapp !important;
  border: 1px solid $whatsapp;
  &:not(.button--disabled):not(:disabled):hover {
    background-color: darken($whatsapp, 10%) !important;
    border: 1px solid darken($whatsapp, 10%);
  }
}
.button--lineapp.js-sharing-btn {
  $lineapp: $blue; // not using brand color because of a11y
  background-color: $lineapp !important;
  border: 1px solid $lineapp;
  &:not(.button--disabled):not(:disabled):hover {
    background-color: darken($lineapp, 10%) !important;
    border: 1px solid darken($lineapp, 10%);
  }
}

//  Forms
// -------------------------

.text-input {
  font-family: inherit;
  background: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: .5rem .75rem;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
}

.text-input:disabled,
.text-input--small:disabled {
  opacity: 0.3;
}

// Components & Misc
// -------------------------

.card {
  box-shadow: 0 1px 1px rgba(173, 168, 168, .1);
  border: $border-lighter;
  background-color: $white;
  border-radius: $border-radius;
}

.circle {
  border-radius: 50%;
}

.rounded {
  border-radius: $border-radius;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

// Unsupported fallback
// -------------------------
.enhanced-ux {
  .js-unsupported-fallback {
    display: none;
  }
}

.js-hidden {
  display: none !important;
}
