.ad-awareness {
  @include solid-breakpoint("sm") {
    padding-left: $space-2;
    padding-right: $space-2;
  }
}

.ad-flexible--spotlight {
  font-weight: $regular;
}

.ad-awareness.ad--rendered {
  &,
  & + .sticky-filler {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include solid-breakpoint("sm") {
    /* Remove all margins from full-width and sticky awareness cards on desktop */
    &.sticky--fixed.sticky--show,
    &.ad-awareness--full-width.sticky--show {
      &,
      & + .sticky-filler {
        margin: 0;
      }
    }

    // @todo Move to adlib
    &.sticky--fixed {
      position: fixed;
    }

    &.ad-flexible--programmatic {
      margin-top: 0;
    }
  }
}
