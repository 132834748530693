.ad-wireframe-wrapper--inline {
  position: relative;

  @include solid-breakpoint("sm") {
    left: 50%;
    transform: translateX(-50%);
  }
}

.ad-flexible--bfp_spotlight_unit.ad-promo-inline {
  [id^="bf-item"] {
    width: inherit;
  }

  @include solid-breakpoint("sm") {
    &.ad-ex--inline .ad-disclosure-text {
      margin: 0 0 $space-1;
      font-size: 12px;
    }
  }
}

