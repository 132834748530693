h2.subbuzz__title {
  span {
    html[lang="ja"] main & {
      line-height: 1.5;
    }
  }
}

.subbuzz .subbuzz__description {
  @include jp-font-size('text-5');

  p {
    html[lang="ja"] main & {
      line-height: 1.5;
    }
  }
}

// Special case of long news posts rendered via BF styles (like UK news)
.subbuzzes-wrapper.subbuzzes--buzzfeed:not(.buzz--list) {
  .subbuzz-text p {
    margin-bottom: 1.5rem;
  }
}

// BFN text subbuzz overrides
.subbuzzes-wrapper.subbuzzes--buzzfeed_news {
  .subbuzz-bfp--custom_embed blockquote,
  .subbuzz-text blockquote {
    padding-right: 0;
  }
  .subbuzz-pullquote,
  .subbuzz-pullquote .pullquote {
    font-family: $basier !important;
    font-weight: $news-w-medium !important;
    color: $news-c-black !important;
  }
  .subbuzz-text p,
  .subbuzz-text ul,
  .subbuzz-text ol {
    a {
      &:hover {
        @include text-underline($news-c-yellow);
      }
    }
  }
}
