@use "sass:math";

.subbuzz .subbuzz-quiz__result-card  {
  ul.subbuzz-quiz__share-container {
    padding-left: 1rem;
    @include breakpoint("sm") {
      padding-left: 0;
    }
  }
  .subbuzz-quiz__button-retake p {
    font-size: 0.875rem;
    line-height: 1.3;
    margin-bottom: 0;
  }
  .subbuzz-quiz__button-retake {
    padding-top: $space-2;
    @include breakpoint("sm") {
      padding-top: 0;
    }
  }
  .subbuzz-quiz__result-buzz-title {
    padding-top: 2px;
  }
}

// making it bit more specific so it doesn't get overwritten
.subbuzz-quiz__answer-body {
  &.xs-flex-align-center {
    align-items: center;
  }
}

.quiz-results-carousel {
  margin-right: -1px;
  margin-left: -1px
}
.quiz-results-carousel {
  .result-carousel__item--description {
    padding-bottom: 45px;
  }
  .results-carousel__pagination {
    margin-top: $space-2;
  }
  .card {
    border: none;
  }
}
.compare-results .compare-results__graph {
  width: math.div(5, 12) * 100%;
}

.js-share-button--copy {
  position: relative;
}

// React bpager has narrower main column so image needs to be shorter for a better fit
$result-card-image-container-size: 14.6rem;
.result-card {
  .result-card__gradient-card {
    .result-card__result-img-container {
      @include breakpoint("sm") {
        min-height: $result-card-image-container-size;

        img {
          min-height: $result-card-image-container-size;
        }
      }

      @media (max-width: 40rem) {
        max-height: $result-card-image-container-size;
      }
    }

    .button--small {
      border: 1px solid transparent;
    }
  }
}

// React bpager has a narrower main column so text needs to be downscaled a bit to fit
.quiz-v3-question {
  .tile {
    @include breakpoint("md") {
      max-width: 600px;
    }
  }

  .tile--text {
    @include breakpoint("md") {
      transform: scale(0.8);
    }
  }
}

.quiz-v3-question,
.quiz-v3-answer {
  .tile--text--proxima-xbold {
    font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
    font-weight: 800;
  }
}
