// Featured Post Subbuzzes (aka page_width === 'wide')
// -------------------------

.featured-post .subbuzz {
  p {
    // non-Japanese styling is handled in SRA
    @include jp-font-size('text-5');
    html[lang=ja] main .subbuzzes-wrapper & {
      line-height: 1.5;
    }
    margin-bottom: $space-2;
  }
  .subbuzz__description p {
    margin-bottom: 0;
  }

  ul {
    padding-left: 2rem;
    list-style: unset;
  }

  &.subbuzz-pullquote {
    margin-bottom: $space-4;
  }
  &.subbuzz-text p {
     margin-bottom: $space-3;
  }
}
