@import "fonts";
@import "news_fonts";
@import "reset";
@import "taboola";
@import "type";
@import "solid-polyfill";
@import "consent-ui";
@import "../components/Subbuzzes/subbuzzes";
@import "../components/Drawer/drawer";
@import "../components/Connatix/Elements/elements";


// displays CCPA module in nav
// stylelint-disable-next-line selector-max-id
.show-ccpa #CCPAModule {
  display: block;
}

// used to restrict scrolling for modal
.overflow-hidden {
  overflow: hidden !important;
}