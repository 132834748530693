@import "lib/helpers";
body {
  @include font-size('text-5');
  font-family: $proxima;
  color: $black;
  line-height: 1.25;
}

h1 {
  @include font-size('text-3');
  font-weight: $bold;
  @include breakpoint("sm") {
    @include font-size('text-1');
    line-height: 1.05;
  }
}

h2 {
  @include font-size('text-4');
  font-weight: $bold;
  @include breakpoint("sm") {
    @include font-size('text-3');
  }
}

.regular {
  font-weight: $regular;
}

.bold {
  font-weight: $bold;
}

.metadata-link {
  @include font-size('text-6');
  font-weight: $bold;
  color: $black;
  text-decoration: underline;
  &:hover {
    color: $blue;
    cursor: pointer;
  }
}

.image-credit {
  @include font-size('text-7');
  font-style: italic;
}

.blue-link {
  color: $blue;
  text-decoration: none;

  &:hover {
    color: #093c8f;
    transition: color .15s ease 0s;
  }
}
