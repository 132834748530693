@mixin reorder-credits {
  .subbuzz__figure-footer,
  .subbuzz__caption {
    .subbuzz__description {
      order: 1;
      margin: 0.25rem 0;
    }
  }

  .subbuzz__attribution {
    order: 2;
  }
}
