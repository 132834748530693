// These classes need to be defined globally
// so are imported via main.scss

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 100ms ease;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
